import SmoothScroll from 'smooth-scroll'
var scroll = new SmoothScroll('a[href*="#"]', {
    speed: 900,
    speedAsDuration: true
});


$("form").submit(function(e) {
    console.log('validate');
    var data = {
        "company": document.getElementById("company").value,
        "name": document.getElementById("name").value,
        "email": document.getElementById("email").value,
        "phone": document.getElementById("phone").value,
        "memo": document.getElementById("content").value,
    }
    var submit = document.getElementById("submit");
    submit.disabled = true;
    submit.textContent = "送信中...";
    $.ajax({
        type: "POST",
        url: "./src/sendmail.php",
        data: {
            data: data,
        },
        dataType: "json",
        success: function(event){
            console.log(event);
        },
        error: function(event){
            submit.disabled = false;
            submit.textContent = "送信する";
        },
        complete: function() {
            submit.disabled = false;
            submit.textContent = "送信する";
            window.open('/thank.html', '_self').focus();
        }
    });
});

// Toggle Header
const targetE = document.getElementById("target-position");
const header = document.getElementById("header");

function toggleHeader() {
    const scrollY = this.scrollY;
    const targetY =
        targetE.getBoundingClientRect().width +
        targetE.getBoundingClientRect().top;
    if (scrollY >= targetY) {
        header.setAttribute("style", "opacity: 1; visibility: visible;");
    } else {
        header.setAttribute("style", "");
    }
}

window.addEventListener("scroll", toggleHeader);

